const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = globalThis.Convermax.config?.wheelsFields || [
  'wheel_diameter',
  'wheel_width',
  'wheel_bolt_pattern',
];
const tiresFields = globalThis.Convermax.config?.tiresFields || ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const isVehicleLanding = !!globalThis.Convermax.config?.isVehicleLanding;
const vehicleLandingPageView = globalThis.Convermax.config?.vehicleLandingPageView ?? 'products';

// do not show the Vehicle facet in the current search
if (vehicleLandingPageView === 'products') {
  ignoreFields.push('Vehicle');
}

const { customerCompanyId } = globalThis.Convermax;

// if the theme has a currency selector for markets, the URL should contain a culture code based on the selected currency
const cultureCode = globalThis.Shopify?.routes?.root.slice(0, -1) ?? '';
const searchPageUrl = `${cultureCode}/pages/search`;

const getStoreCurrency = () => globalThis.Shopify.currency?.active;

const handleCurrencies = (selector) => {
  if (window.Currency) {
    // window.Currency.cookie.read() can cause exception "Uncaught TypeError: jQuery.cookie is not a function"
    try {
      const currentCurrency = window.Currency?.cookie.read();
      window.Currency.convertAll(getStoreCurrency(), currentCurrency, selector);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.debug(e.message);
    }
  }
};

function formatPrice(price) {
  if (!price) {
    return '';
  }

  const locale = `${window.Shopify.locale.split('-')[0]}-${window.Shopify.country}`;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: getStoreCurrency(),
  }).format(price);
}

const fieldsForRelated = ['category', ...wheelsFields, ...tiresFields];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

function InitFunc() {
  if (window.document.querySelector('#cm_results, #cm-brand-list')) {
    const unwantedPageWrappers = [
      ['div.rte', 'rte'],
      ['.page__content.rte', 'rte'],
      ['.page-width.page-width--narrow', 'page-width--narrow'],
    ];

    unwantedPageWrappers.forEach(([elemSelector, className]) => {
      const wrapperElem = window.document.querySelector(elemSelector);
      wrapperElem?.classList.remove(className);
    });
  }
}

const updateCallback = () => {
  handleCurrencies('.cm_SearchResult .price-item');

  if (window._affirm_config) {
    window.affirm.ui.ready(function () {
      window.affirm.ui.refresh();
    });
  }
};

export default {
  platform: 'shopify',
  searchPageUrl,
  InitFunc,
  SearchRequestDefaults: {
    pageSize: globalThis.Convermax.config?.pageSize || 36,
    extra: customerCompanyId ? { customerCompanyId } : {},
    sort: window.Convermax.config?.defaultSort ?? 'relevance',
  },
  autocomplete: { requestDefaults: { extra: customerCompanyId ? { customerCompanyId } : {} } },
  product: {
    formatPrice,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      {
        pathname: `${cultureCode}/collections`,
        field: window.Convermax.config?.categorySelectionPageField ?? 'category',
      },
      {
        pathname: window.document.querySelector('#cm-brand-list') ? window.location.pathname : null,
        field: 'vendor',
      },
      {
        pathname:
          isVehicleLanding && vehicleLandingPageView === 'categories' ? window.location.pathname : null,
        field: 'category',
      },
    ],
    categorySelectionPageRedirect: !!window.Convermax.config?.categorySelectionPageRedirect,
    doNotSaveSelectedVehicle: !!window.Convermax.config?.doNotSaveSelectedVehicle,
    expectResponseRedirect: true,
    forceVehicleSelection: !!window.Convermax.config?.forceVehicleSelection,
    isAutoRedirectDisabled: !!window.Convermax.config?.isAutoRedirectDisabled,
    isVehicleSelectionIsolated: !!window.Convermax.config?.isVehicleSelectionIsolated,
    hideVehicleFromUrl: !!window.Convermax.config?.hideVehicleFromUrl,
    vehicleDataExpires: window.Convermax.config?.vehicleDataExpires,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
      visibleIf: () => !isVehicleLanding || vehicleLandingPageView === 'products',
    },
    {
      name: 'CategoryPageSubcategories',
      type: 'FacetTiles',
      location: '#cm-subcategory-list',
      template: 'facetTiles/subtitles',
      visibleIf: () =>
        (!isVehicleLanding || vehicleLandingPageView === 'products') &&
        window.location.pathname !== '/collections/vendors',
      facetField: 'category',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
      sortSelectClass: 'select__select caption-large',
    },
    {
      name: 'SearchBox',
      location: {
        replace: '.search-modal .search-modal__content .search-modal__form',
        class: 'search-modal__form',
      },
      template: 'SearchBox',
      onDropdownItemsReceived: () => handleCurrencies('.cm_search-box-root div.price'),
      onSubmit: () => {
        const modalDialog = window.document.querySelector('.header__search details');
        if (modalDialog) {
          modalDialog.open = false;
        }
      },
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      name: 'SearchBoxDialogButton',
      location: { insertAfter: '.header__search', class: 'header__search cm_desktop-hide' },
      template: 'searchBox/dialogButtonMobile',
      onDropdownItemsReceived: () => handleCurrencies('.cm_search-box-root__dialog div.price'),
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: !!window.Convermax.config?.ymmInitCollapsed,
      columnBreakpoint: 800,
    },
    {
      name: 'HeaderVehicleWidgetStub',
      type: 'VehicleWidget',
      location: '#cm-ymm-stub',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: !!window.Convermax.config?.ymmInitCollapsed,
      columnBreakpoint: 800,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'VehicleSpecs',
      type: 'VehicleWidget',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && extraFitmentFields,
      visibleIf: () => !!window.Convermax.config?.extraFieldsAsFilters,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: '#cm-garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: '#cm-mobile-garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SingleVehicleGarage',
      location: { selector: '#cm-single-garage', class: 'cm-single-garage' },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'MobileSingleVehicleGarage',
      type: 'SingleVehicleGarage',
      location: { selector: '#cm-mobile-single-garage', class: 'cm-mobile-single-garage' },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { selector: '#cm-verify-fitment', class: 'cm_vehicle-widget__verify-fitment_container' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'VerifyFitmentStub',
      type: 'VehicleWidget',
      location: '#cm-verify-fitment-stub',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm-collection-list',
      template: 'facetTiles/hierarchicalContainer',
      isMultiLevel: !!window.Convermax.config?.categoriesIsMultiLevel,
      isVehicleWidgetDisabled: !!window.document.querySelector('#cm-ymm'),
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm-brand-list',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      isVehicleWidgetDisabled: !!window.document.querySelector('#cm-ymm'),
    },
    {
      name: 'VehicleLandingPageCategoryTiles',
      type: 'FacetTiles',
      location: '#cm-collection-page',
      template: 'facetTiles/hierarchicalContainer',
      isMultiLevel: !!window.Convermax.config?.categoriesIsMultiLevel,
      visibleIf: () => isVehicleLanding && vehicleLandingPageView === 'categories',
    },
    {
      name: 'RelatedItems',
      location: '#cm-related-products',
      template: 'product/relatedItems',
      count: window.Convermax.config?.relatedItemsCount || 4,
      getSelection,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      initCollapsed: window.Convermax.config?.facetDialogInitCollapsed ?? true,
      ignoreFields: ['Universal'],
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
      onDropdownItemsReceived: () => handleCurrencies('.cm_search-box-root__dialog div.price'),
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
